import React from 'react';
import NavigationBar from './Navbar'; // Importa a Navbar
import instagramIcon from '../assets/instagram-icon.png'; // Supondo que o ícone esteja disponível em assets

const About = () => {
  return (
    <div className="container">
      {/* Cabeçalho */}
      <header>
        <h1>Dr. Murillo Cunegatto Maçullo Braga</h1>
        <p className="specialty">Neurocirurgião em Teresópolis, RJ</p>
        <p className="credentials">CRM 52-112083-2</p>
        <p className="credentials">RQE 50951</p>
      </header>

      {/* Navbar renderizada logo abaixo do cabeçalho */}
      <NavigationBar />

      {/* Seção Sobre Mim */}
      <section id="sobre">
        <h2>Sobre Dr. Murillo Cunegatto</h2>
        <p>
          Sou Médico e especialista em Neurocirurgia. Minha vida na medicina tem sido uma trajetória contínua de aprendizado e dedicação.
        </p>

        <h3>Início da Jornada</h3>
        <p>
          Minha história começa em Teresópolis, Rio de Janeiro, onde nasci e dei os primeiros passos na medicina. Desde cedo, senti uma forte conexão com a neurocirurgia, fascinado pela complexidade, beleza e desafios impostos pelo sistema nervoso. A medicina sempre foi mais do que uma profissão para mim; é uma paixão que me move todos os dias.
        </p>

        <h3>Explorando Novos Horizontes</h3>
        <p>
          Houve um momento em que considerei expandir meus horizontes e me especializar nos Estados Unidos. Nesse período, revalidei meu diploma após ser aprovado nos Steps do USMLE, um desafio que testou meu conhecimento e habilidades. No entanto, após muita reflexão, decidi que meu lugar era no Brasil, onde eu aprofundei meus estudos em Neurocirurgia.
        </p>

        <h3>Estabelecendo Raízes em São Paulo</h3>
        <p>
          Mudei-me para São Paulo, um centro de excelência médica, onde completei minha Residência Médica em Neurocirurgia no Hospital Santa Marcelina. Essa experiência foi fundamental para o meu desenvolvimento profissional, permitindo-me aprimorar minhas habilidades cirúrgicas e enriquecer profissionalmente.
        </p>

        <h3>Retorno às Origens</h3>
        <p>
          Após a residência, decidi retornar ao Estado do Rio de Janeiro. Foi uma escolha pessoal, movida pelo desejo de aplicar meus conhecimentos e habilidades no lugar onde tudo começou. Aqui, me dedico ao tratamento de doenças do sistema nervoso, buscando sempre proporcionar o melhor atendimento aos meus pacientes.
        </p>

        <h3>Compromisso com a Excelência</h3>
        <p>
          Sou apaixonado pelo que faço e estou determinado a oferecer o melhor tratamento possível. Cada dia é uma oportunidade para melhorar, para crescer profissionalmente e, acima de tudo, para fazer a diferença na vida das pessoas que confiam em mim para cuidar da sua saúde.
        </p>
      </section>

      {/* Rodapé */}
      <footer>
        <div className="instagram">
          <strong>Siga-me no Instagram:</strong> 
          <a href="https://www.instagram.com/mcneurocirurgia/" target="_blank" rel="noopener noreferrer">@mcneurocirurgia</a>
          <img src={instagramIcon} alt="Instagram" style={{ width: '24px', height: '24px', marginLeft: '10px' }} />
        </div>
        <p>© 2023 Dr. Murillo Cunegatto Maçullo Braga. Todos os direitos reservados.</p>
      </footer>
    </div>
  );
};

export default About;
