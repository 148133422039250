import React from 'react';
import NavigationBar from './Navbar'; // Importa a Navbar
import instagramIcon from '../assets/instagram-icon.png'; // Supondo que o ícone esteja disponível em assets
import whatsappIcon from '../assets/whatsapp-icon.png'; // Supondo que o ícone esteja disponível em assets

const Home = () => {
  return (
    <div className="container">
      {/* Cabeçalho */}
      <header>
        <h1>Dr. Murillo Cunegatto Maçullo Braga</h1>
        <p className="specialty">Neurocirurgião em Teresópolis, RJ</p>
        <p className="credentials">CRM 52-112083-2</p>
        <p className="credentials">RQE 50951</p>
      </header>

      {/* Navbar é renderizada logo abaixo do cabeçalho */}
      <NavigationBar />

      {/* Seção de Áreas de Atuação */}
      <section id="areas-de-atuacao">
        <h2>Áreas de Atuação</h2>
        <p>
          Dores na coluna<br />
          Dor de cabeça<br />
          Hérnia de disco<br />
          Cirurgia da coluna vertebral<br />
          Artrose na coluna<br />
          Fratura da coluna<br />
          Doenças da coluna<br />
          Tumor cerebral<br />
          Espasticidade<br />
          Hidrocefalia<br />
          Aneurisma cerebral<br />
          Malformação arteriovenosa cerebral<br />
          AVC<br />
          Convulsões
        </p>
      </section>

      {/* Seção de Contato */}
      <section id="contato">
        <h2>Contato</h2>
        <p><strong>Consultório:</strong> Avenida Feliciano Sodré, 300, Sala 321, Teresópolis, RJ.<br />Telefone: (21) 99115-9466</p>
        <p>
          <a href="https://wa.me/5521991159466?text=Olá! Gostaria de agendar um atendimento com Dr Murillo Cunegatto Neurocirurgião." className="btn btn-link text-success">
            Clique aqui para agendar seu atendimento no <strong>consultório</strong> pelo WhatsApp
            <img src={whatsappIcon} alt="WhatsApp" style={{ verticalAlign: 'middle', width: '20px', height: '20px', marginLeft: '10px' }} />
          </a>
        </p>

        <p><strong>Clínica de Ortopedia de Teresópolis (COT):</strong> Rua Francisco Sá, 336, Centro, Teresópolis, RJ.<br />Telefone: (21) 2742-4455</p>
        <p>
          <a href="https://wa.me/552127424455?text=Olá! Gostaria de agendar um atendimento com Dr Murillo Cunegatto Neurocirurgião." className="btn btn-link text-success">
            Clique aqui para agendar seu atendimento na <strong>COT</strong> pelo WhatsApp
            <img src={whatsappIcon} alt="WhatsApp" style={{ verticalAlign: 'middle', width: '20px', height: '20px', marginLeft: '10px' }} />
          </a>
        </p>
      </section>

      {/* Rodapé */}
      <footer>
        <div className="instagram">
          <strong>Siga-me no Instagram:</strong> 
          <a href="https://www.instagram.com/mcneurocirurgia/" target="_blank" rel="noopener noreferrer">@mcneurocirurgia</a>
          <img src={instagramIcon} alt="Instagram" style={{ width: '24px', height: '24px', marginLeft: '10px' }} />
        </div>
        <p>© 2023 Dr. Murillo Cunegatto Maçullo Braga. Todos os direitos reservados.</p>
      </footer>
    </div>
  );
};

export default Home;
